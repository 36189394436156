import React from "react";

function RotateRightIcon() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_12_1859)">
        <path
          d="M18.4 11.1C16.55 9.49 14.15 8.5 11.5 8.5C7.34004 8.5 3.76004 10.92 2.06004 14.43C1.74004 15.1 2.10004 15.9 2.81004 16.14C3.40004 16.34 4.04004 16.06 4.31004 15.5C5.61004 12.84 8.34004 11 11.5 11C13.45 11 15.23 11.72 16.62 12.88L14.71 14.79C14.08 15.42 14.52 16.5 15.41 16.5H21C21.55 16.5 22 16.05 22 15.5V9.91C22 9.02 20.92 8.57 20.29 9.2L18.4 11.1Z"
          fill="#5E5E5E"
        />
      </g>
      <defs>
        <clipPath id="clip0_12_1859">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default RotateRightIcon;
