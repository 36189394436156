import { ItemTypeEnum, NumericEnum } from "common/enums";
import { Point, Size } from "common/classes";
import { Item } from "./Item";
import { Canvas } from "./Canvas";

export class Marker extends Item {
  public description = "";
  public color = "#ff0000";
  public naming: NumericEnum = NumericEnum.alphabet;

  constructor(canvas: Canvas, width: number, height: number, public text: string | number = "") {
    super(canvas);
    this.type = ItemTypeEnum.marker;
    this.size = new Size(width, height);
  }

  private paint() {
    this.canvas.ctx.beginPath();
    this.canvas.ctx.roundRect(
      this.position.x,
      this.position.y,
      this.size.width,
      this.size.height,
      50
    );
    this.canvas.ctx.fillStyle = this.color;
    this.canvas.ctx.fill();
    this.canvas.ctx.closePath();
    this.canvas.ctx.beginPath();
    this.canvas.ctx.font = "24px 'Kyiv*Type Sans'";
    this.canvas.ctx.fillStyle = "white";
    this.canvas.ctx.textAlign = "center";
    this.canvas.ctx.textBaseline = "middle";
    this.canvas.ctx.fillText(
      this.text.toString(),
      this.position.x + 20,
      this.position.y + 20
    );
    this.canvas.ctx.closePath();
    this.canvas.ctx.fill();
  }

  draw(x: number, y: number) {
    super.draw(x, y);
    this.paint();
  }

  refresh() {
    super.refresh();

    this.position = new Point(this.position.x, this.position.y);
    this.paint();
  }
}
