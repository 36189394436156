import { Point, Size } from "common/classes";
import { ItemTypeEnum } from "common/enums";
import { Item } from "./Item";
import { Canvas } from "./Canvas";

export class Picture extends Item {
  private image: HTMLImageElement;

  constructor(canvas: Canvas, file: File, onLoad: (picture: Picture) => void) {
    super(canvas);

    this.type = ItemTypeEnum.picture;
    this.image = new Image();
    this.image.onload = () => {
      this.size = new Size(this.image.width, this.image.height);
      onLoad(this);
    };
    this.image.src = URL.createObjectURL(file);
  }

  paint() {
    this.canvas.ctx.drawImage(
      this.image,
      0,
      0,
      this.canvas.size.width,
      this.canvas.size.height
    );
  }

  draw(x: number, y: number) {
    super.draw(x, y);
    this.paint();
  }

  refresh() {
    super.refresh();

    this.position = new Point(this.position.x, this.position.y);
    this.paint();
  }
}
