import React from "react";

function MinusIcon() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_12_1861)">
        <path
          d="M7 11.5H17C17.55 11.5 18 11.95 18 12.5C18 13.05 17.55 13.5 17 13.5H7C6.45 13.5 6 13.05 6 12.5C6 11.95 6.45 11.5 7 11.5Z"
          fill="#5E5E5E"
        />
      </g>
      <defs>
        <clipPath id="clip0_12_1861">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default MinusIcon;
