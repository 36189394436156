import React, { SyntheticEvent, useState } from "react";

import CloudIcon from "common/icons/cloud";
import "./Drop.scss";

type Props = {
  onDrop: (file: File | null) => void;
};

function Drop(props: Props) {
  const [isDragActive, setIsDragActive] = useState(false);

  const onDragLeave = () => {
    setIsDragActive(false);
  };

  const onDrop = (e: any) => {
    e.preventDefault();
    setIsDragActive(false);

    const files = [...e.dataTransfer.items]
      .filter((item) => item.kind === "file")
      .map((item) => item.getAsFile());

    props.onDrop(files[0] ?? null);
  };

  const onChange = (e: any) => {
    e.preventDefault();

    props.onDrop(e.currentTarget.files[0] ?? null);
  };

  const onDragOver = (e: SyntheticEvent) => {
    e.preventDefault();
    setIsDragActive(true);
  };

  return (
    <div className="drop-component">
      <div
        className={`drop-area-container ${isDragActive ? "active" : ""}`}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <div className="drop-area-content">
          <div className="title">Upload your picture here</div>
          <div className="subtitle">(or drag and drop)</div>
          <CloudIcon></CloudIcon>
          <div className="file-selector">
            <input type="file" onChange={onChange}/>
            <button>Upload Image</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Drop;
