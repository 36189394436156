import React, { useState } from "react";
import { ColorResult, HuePicker } from "react-color";

import TrashIcon from "common/icons/trash";
import CogsIcon from "common/icons/cogs";
import { NumericEnum } from "common/enums";
import { Marker as MarkerItem } from "../../classes/Marker";
import "./Marker.scss";

type Props = {
  item: MarkerItem;
  onDelete: (item: MarkerItem) => void;
  onColorChange: (item: MarkerItem) => void;
  onNumericChange: (item: MarkerItem) => void;
};

function Marker(props: Props) {
  const [isSettingsVisible, setSettingsVisible] = useState<boolean>(false);

  const onTrashClick = () => {
    props.onDelete(props.item);
  };

  const onSettingsClick = () => {
    setSettingsVisible(!isSettingsVisible);
  };

  const onInput = (e: any) => {
    props.item.description = (e.currentTarget as HTMLInputElement).value;
  };

  const onColorChange = (e: ColorResult) => {
    props.item.color = e.hex;
    props.onColorChange(props.item);
  };

  const onNumericChange = (e: any) => {
    props.item.naming = e.target.value;
    props.onNumericChange(props.item);
  };

  return (
    <div className="marker-component">
      <div className="header">
        <div className="name">Marker {props.item.text}</div>
        <div className="icons">
          <div className="icon">
            <span onClick={onSettingsClick}>
              <CogsIcon />
            </span>
          </div>
          <div className="icon">
            <span onClick={onTrashClick}>
              <TrashIcon />
            </span>
          </div>
        </div>
      </div>
      <div className="content">
        <input type="text" placeholder="Enter description" onInput={onInput} />
      </div>
      {isSettingsVisible ? (
        <div className="settings">
          <HuePicker
            color={props.item.color}
            onChange={onColorChange}
          />

          <div className="radio-buttons" onChange={onNumericChange}>
            <div className="radio-box">
              <input
                type="radio"
                name={`${props.item.uuid}-number`}
                defaultChecked={props.item.naming === NumericEnum.numeric}
                id={`${props.item.uuid}-numeric-number`}
                value={NumericEnum.numeric}
              />
              <label htmlFor={`${props.item.uuid}-numeric-number`}>
                Numeric Number
              </label>
            </div>

            <div className="radio-box">
              <input
                type="radio"
                name={`${props.item.uuid}-number`}
                defaultChecked={props.item.naming === NumericEnum.alphabet}
                id={`${props.item.uuid}-alphabet-number`}
                value={NumericEnum.alphabet}
              />
              <label htmlFor={`${props.item.uuid}-alphabet-number`}>
                Alphabet Number
              </label>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Marker;
