import React, { useState } from "react";

import Drop from "../drop/Drop";
import Editor from "../editor/Editor";
import "./App.scss";

function App() {
  const [file, setFile] = useState<File | null>(null);

  const onDrop = (droppedFile: File | null) => {
    if (droppedFile) {
      setFile(droppedFile);
    }
  };

  const onCancel = () => {
    setFile(null);
  };

  return (
    <>
      <header>
        <div className="logo">Logo</div>
      </header>
      <main>
        {!file ? (
          <Drop onDrop={onDrop}></Drop>
        ) : (
          <Editor file={file} onCancel={onCancel}></Editor>
        )}
      </main>
    </>
  );
}

export default App;
