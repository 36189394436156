import React from "react";

function CloudIcon() {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11_130)">
        <path
          d="M64.5 33.4666C62.2333 21.9666 52.1333 13.3333 40 13.3333C30.3667 13.3333 22 18.8 17.8333 26.8C7.8 27.8666 0 36.3666 0 46.6666C0 57.7 8.96667 66.6666 20 66.6666H63.3333C72.5333 66.6666 80 59.2 80 50C80 41.2 73.1667 34.0666 64.5 33.4666ZM63.3333 60H20C12.6333 60 6.66667 54.0333 6.66667 46.6666C6.66667 39.8333 11.7667 34.1333 18.5333 33.4333L22.1 33.0666L23.7667 29.9C26.9333 23.8 33.1333 20 40 20C48.7333 20 56.2667 26.2 57.9667 34.7666L58.9667 39.7666L64.0667 40.1333C69.2667 40.4666 73.3333 44.8333 73.3333 50C73.3333 55.5 68.8333 60 63.3333 60ZM26.6667 43.3333H35.1667V53.3333H44.8333V43.3333H53.3333L40 30L26.6667 43.3333Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_11_130">
          <rect width="80" height="80" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CloudIcon;
