import React from "react";

function PlusIcon() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_12_1863)">
        <path
          d="M18 13.5H13V18.5C13 19.05 12.55 19.5 12 19.5C11.45 19.5 11 19.05 11 18.5V13.5H6C5.45 13.5 5 13.05 5 12.5C5 11.95 5.45 11.5 6 11.5H11V6.5C11 5.95 11.45 5.5 12 5.5C12.55 5.5 13 5.95 13 6.5V11.5H18C18.55 11.5 19 11.95 19 12.5C19 13.05 18.55 13.5 18 13.5Z"
          fill="#5E5E5E"
        />
      </g>
      <defs>
        <clipPath id="clip0_12_1863">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default PlusIcon;
