import { MouseEvent } from "react";

import { Point, Size } from "common/classes";
import { ItemTypeEnum } from "common/enums";
import { Item } from "./Item";

export class Canvas {
  ctx: CanvasRenderingContext2D;
  clientRect!: DOMRect;
  size!: Size;
  items: Item[] = [];
  gridPadding = 50;
  moveStartPosition: Point | null = null;

  constructor(private element: HTMLCanvasElement) {
    this.ctx = element.getContext("2d")!;
    this.refreshSize();
  }

  refreshSize() {
    this.size = new Size(this.element.offsetWidth, this.element.offsetHeight);
    this.clientRect = this.element.getBoundingClientRect();
    this.element.width = this.element.offsetWidth;
    this.element.height = this.element.offsetHeight;
  }

  // ITEMS

  add(item: Item) {
    const items = this.getByType(item.type);
    const last = items[items.length - 1];
    item.id = last ? last.id + 1 : 0;
    this.items.push(item);
  }

  remove(item: Item) {
    const foundIndex = this.items.findIndex(
      (alreadyItem) => alreadyItem.uuid === item.uuid
    );

    if (foundIndex === -1) {
      return;
    }

    this.items.splice(foundIndex, 1);
  }

  getByType(type: ItemTypeEnum) {
    return this.items.filter((item) => item.type === type);
  }

  // DRAW

  clear() {
    this.ctx.clearRect(0, 0, this.size.width, this.size.height);
  }

  grid() {
    for (let x = 0; x <= this.size.width; x += this.gridPadding) {
      this.ctx.moveTo(x, 0);
      this.ctx.lineTo(x, this.size.height);
    }

    for (let y = 0; y <= this.size.height; y += this.gridPadding) {
      this.ctx.moveTo(0, y);
      this.ctx.lineTo(this.size.width, y);
    }

    this.ctx.strokeStyle = "#24629B33";
    this.ctx.stroke();
    this.ctx.fill();
  }

  draw() {
    this.clear();
    this.getByType(ItemTypeEnum.picture).forEach((item) => item.draw(item.position.x, item.position.y));
    this.grid();
    this.getByType(ItemTypeEnum.marker).forEach((item) => item.draw(item.position.x, item.position.y));
  }

  refresh() {
    this.clear();
    this.getByType(ItemTypeEnum.picture).forEach((item) => item.refresh());
    this.grid();
    this.getByType(ItemTypeEnum.marker).forEach((item) => item.refresh());
  }

  refreshWithoutGrid() {
    this.clear();
    this.getByType(ItemTypeEnum.picture).forEach((item) => item.refresh());
    this.getByType(ItemTypeEnum.marker).forEach((item) => item.refresh());
  }

  // MOVE

  moveCapture(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();

    const point = new Point(
      e.clientX - this.clientRect.left,
      e.clientY - this.clientRect.top
    );

    const item = this.items
      .slice(0)
      .reverse()
      .find(
        (item) =>
          point.x > item.position.x &&
          point.x < item.position.x + item.size.width &&
          point.y > item.position.y &&
          point.y < item.position.y + item.size.height
      );

    if (!item || !item.isCanBeDragging) {
      return;
    }

    item.isDragging = true;
    this.moveStartPosition = point;
  }

  moveUnCapture(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();

    this.items.forEach((item) => {
      item.isDragging = false;
    });

    this.moveStartPosition = null;
  }

  move(e: MouseEvent) {
    if (this.moveStartPosition) {
      e.preventDefault();
      e.stopPropagation();

      const point = new Point(
        e.clientX - this.clientRect.left,
        e.clientY - this.clientRect.top
      );

      this.items.forEach((item) => {
        if (!item.isDragging) {
          return;
        }

        item.move(
          point.x - this.moveStartPosition!.x,
          point.y - this.moveStartPosition!.y
        );
      });

      this.moveStartPosition = point;
    }
  }
}
