import React from "react";

function RotateLeftIcon() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_12_1857)">
        <path
          d="M12.5 8.5C9.85 8.5 7.45 9.49 5.6 11.1L3.71 9.21C3.08 8.58 2 9.02 2 9.91V15.5C2 16.05 2.45 16.5 3 16.5H8.59C9.48 16.5 9.93 15.42 9.3 14.79L7.39 12.88C8.78 11.72 10.55 11 12.51 11C15.67 11 18.4 12.84 19.7 15.5C19.97 16.06 20.61 16.34 21.2 16.14C21.91 15.91 22.27 15.1 21.95 14.42C20.23 10.92 16.65 8.5 12.5 8.5Z"
          fill="#5E5E5E"
        />
      </g>
      <defs>
        <clipPath id="clip0_12_1857">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default RotateLeftIcon;
