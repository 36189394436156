import React from "react";

import CloseIcon from "common/icons/close";
import { Marker } from "../../classes/Marker";
import "./Save.scss";

type Props = {
  items: Marker[];
  image: string;
  onCancel: () => void;
  onSave: () => void;
};

function Save(props: Props) {
  return (
    <div className="save-component">
      <div className="content">
        <button className="close" onClick={props.onCancel}>
          <CloseIcon />
        </button>
        <div className="markers">
          <div className="title">Let's check added markers:</div>
          <div className="list">
            {props.items.length ? (
              props.items.map((item, index) => (
                <div className="marker" key={item.uuid}>
                  <div className="value" style={{ background: item.color }}>
                    {item.text}
                  </div>
                  <div className="name">{item.description}</div>
                </div>
              ))
            ) : (
              <div className="empty">Markers not found</div>
            )}
          </div>
          <div className="buttons">
            <button className="cancel" onClick={props.onCancel}>
              Cancel
            </button>
            <button className="save" onClick={props.onSave}>
              Save
            </button>
          </div>
        </div>

        <div className="image">
          <img src={props.image} alt="" />
        </div>
      </div>
    </div>
  );
}

export default Save;
