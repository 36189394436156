export enum ItemTypeEnum {
  none = "none",
  picture = "picture",
  marker = "marker",
}

export enum NumericEnum {
  alphabet = "alphabet",
  numeric = "numeric",
}
