import React, { createRef, useEffect, useState } from "react";

import RotateRightIcon from "common/icons/rotate-right";
import RotateLeftIcon from "common/icons/rotate-left";
import MinusIcon from "common/icons/minus";
import PlusIcon from "common/icons/plus";
import { ItemTypeEnum, NumericEnum } from "common/enums";
import { ALPHABETICAL } from "common/constants";
import MarkerComponent from "./components/marker/Marker";
import { Canvas } from "./classes/Canvas";
import { Marker } from "./classes/Marker";
import { Picture } from "./classes/Picture";
import Save from "./components/save/Save";
import "./Editor.scss";

type Props = {
  file: File;
  onCancel: () => void;
};

function Editor(props: Props) {
  // CANVAS
  const canvasRef = createRef<HTMLCanvasElement>();
  const [canvas, setCanvas] = useState<Canvas | null>();
  const [markersList, setMarkersList] = useState<Marker[] | null>();
  const [scale] = useState(100);
  const [canvasData, setCanvasData] = useState<string | null>(null);

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }

    const cnv = new Canvas(canvasRef.current);
    setCanvas(cnv);
  }, [props.file]);

  useEffect(() => {
    if (!canvas) {
      return;
    }

    // SETUP IMAGE
    canvas!.add(
      new Picture(canvas!, props.file, (picture) => {
        picture.isCanBeDragging = false;
        picture.draw(
          canvas.size.width / 2 - picture.size.width / 2,
          canvas.size.height / 2 - picture.size.height / 2
        );
        canvas.grid();
      })
    );
  }, [canvas]);

  const addMarker = () => {
    if (!canvas) {
      return;
    }

    const marker = new Marker(canvas, 40, 40);
    canvas.add(marker);

    marker.text = ALPHABETICAL[marker.id];
    marker.draw(
      canvas.size.width / 2 - marker.size.width / 2,
      canvas.size.height / 2 - marker.size.height / 2
    );

    setMarkersList([...(canvas.getByType(ItemTypeEnum.marker) as Marker[])]);
  };

  const onDeleteMarker = (item: Marker) => {
    if (!canvas) {
      return;
    }

    canvas.remove(item);
    canvas.draw();
    setMarkersList([...(canvas.getByType(ItemTypeEnum.marker) as Marker[])]);
  };

  const onColorMarkerChange = (item: Marker) => {
    if (!canvas) {
      return;
    }

    canvas.refresh();
  };

  const onNumericMarkerChange = (item: Marker) => {
    if (!canvas) {
      return;
    }

    item.text =
      item.naming === NumericEnum.numeric ? item.id + 1 : ALPHABETICAL[item.id];

    canvas.refresh();
  };

  const onSave = () => {
    if (!canvasRef.current || !canvas) {
      return;
    }

    canvas.refreshWithoutGrid();

    setCanvasData(canvasRef.current?.toDataURL("image/png"));

    canvas.refresh();
  };

  const onSaveConfirmed = () => {
    if (!canvasRef.current && canvasData) {
      return;
    }

    const link = document.createElement("a");
    link.download = "pattern.png";
    link.href = canvasData!;
    link.click();
  };

  const onSaveCancel = () => {
    setCanvasData(null);
  };

  return (
    <div className="editor-component">
      {canvasData ? (
        <Save
          image={canvasData}
          items={markersList ?? []}
          onCancel={onSaveCancel}
          onSave={onSaveConfirmed}
        />
      ) : (
        <></>
      )}
      <div className="editor-container">
        <div className="header">
          Let's work on it together now! I am adding a grid
        </div>
        <div className="content">
          <canvas
            ref={canvasRef}
            onMouseDown={(e) => canvas?.moveCapture(e)}
            onMouseUp={(e) => canvas?.moveUnCapture(e)}
            onMouseMove={(e) => canvas?.move(e)}
          ></canvas>
        </div>
        <div className="actions">
          <div className="left disabled">
            <div className="button-wrapper restore">
              <button disabled>Restore Original</button>
            </div>
            <div className="button-wrapper rotate">
              <button disabled>
                <RotateLeftIcon />
              </button>
              <div className="separator"></div>
              <button disabled>
                <RotateRightIcon />
              </button>
            </div>
            <div className="button-wrapper scale">
              <button disabled>
                <MinusIcon />
              </button>
              <div className="value">{scale}%</div>
              <button disabled>
                <PlusIcon />
              </button>
            </div>
          </div>
          <div className="right">
            <div className="button-wrapper cancel">
              <button onClick={props.onCancel}>Cancel</button>
            </div>
            <div className="button-wrapper save">
              <button onClick={onSave}>Save</button>
            </div>
          </div>
        </div>
      </div>
      <div className="markers-container">
        <div className="markers-list">
          {markersList?.length ? (
            <>
              {markersList.map((item, index) => (
                <MarkerComponent
                  key={index}
                  item={item}
                  onDelete={onDeleteMarker}
                  onColorChange={onColorMarkerChange}
                  onNumericChange={onNumericMarkerChange}
                />
              ))}
              <hr />
            </>
          ) : (
            <></>
          )}
        </div>
        <button onClick={addMarker}>Add marker</button>
      </div>
    </div>
  );
}

export default Editor;
