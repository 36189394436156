import { v4 } from "uuid";

import { ItemTypeEnum } from "common/enums";
import { Point, Size } from "common/classes";
import { Canvas } from "./Canvas";

interface IItem {
  draw: (x: number, y: number) => void;
  refresh: () => void;
}

export class Item implements IItem {
  protected canvas: Canvas;
  id = 0;
  uuid = v4();
  type: ItemTypeEnum = ItemTypeEnum.none;
  position: Point = new Point();
  size: Size = new Size();
  isDragging: boolean = false;
  isCanBeDragging: boolean = true;

  constructor(canvas: Canvas) {
    this.canvas = canvas;
  }

  draw(x: number, y: number) {
    this.position = new Point(x, y);
  }

  refresh() {}

  move(x: number, y: number) {
    this.position.x += x;
    this.position.y += y;
    this.canvas.draw();
  }
}
